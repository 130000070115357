import * as React from "react"
import {useState, useEffect} from 'react';
import axios from 'axios';
import Header from "../partials/header"
import Footer from "../partials/footer"
import ReactMarkdown from 'react-markdown'
import HeaderTitle from "../components/HeaderTitle";

const BlogPage = (props, data) => {
    const [blogs, setBlog] = useState(false);
    useEffect(() => {
        fetchData(props.pageContext.slug);
    }, [props.pageContext.slug])

    const fetchData = (slug) => {
        axios.get(`${process.env.API_URL}/blogs/findBySlug/${slug}`).then((res) => {
            setBlog(res.data);
        }).catch((error) => {
            console.log(error);
        });
    };

    if (blogs === false) {
        return (<>Loading...</>);
    }

    return (
        <main>
            <Header/>
            <div className="container changeheading pt-60 mb-30">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="cat-header text-center">
                            <HeaderTitle title={blogs.title}/>
                        </div>
                        <div className="row">
                            <ReactMarkdown children={blogs.description}/>,
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default BlogPage
